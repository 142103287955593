// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options

// Customize some defaults

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

$light: #F5F7FB;
$dark: #3B3C3D;
$primary: #69BEFF;
$secondary: #232E62;

$body-color: $dark;
$font-family-base: 'Rubik', sans-serif;
$body-bg: #fff;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1260px
) !default;

$grid-gutter-width: 20px;

$h1-font-size: rem-calc(64);
$h2-font-size: rem-calc(40);
$h3-font-size: rem-calc(32);

$btn-border-width: 2px;
$btn-padding-x: 1.25rem;
$btn-border-radius: 40px;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: .625rem;
$btn-border-radius-lg: 50px;

$navbar-padding-y: .3125rem;
$navbar-nav-link-padding-x: .75rem;
$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$card-border-radius: 10px;
$card-border-width: 0;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

// #region Navbar 
  .navbar{
    &-brand{
      margin-right: 0;
      margin-left: 1rem;
    }
    &-nav{
      .nav-link{
        font-size: rem-calc(14);
        line-height: 1.42857;
      }
      .dropdown-item{text-align: center;}
    }
    .btn, .dropdown-item{
      font-size: rem-calc(14);
      line-height: 1.42857;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
    .dropdown-menu{
      background-color: $light;
      border:0;
      box-shadow: 0 4px 10px rgba(182, 182, 182, 0.29);
      left:50%;
      transform: translateX(-50%);
    }
    .dropdown-toggle::after{border-top-color: $primary;vertical-align: .14em;}
  }
  .navbar-collapse-inner{
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
  }

  .hamburger {
    $this: &;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 rem-calc(-14) 0 0;
    overflow: visible; 
    display: block;
    padding:rem-calc(15) rem-calc(14);
    &-box{
      width: 22px;
      height: 20px;
      display: block;
      position: relative;
    }
    &-inner{
      display: block;
      top: 50%;
      margin-top: -1px;
      &, &::before, &::after{
        width: 22px;
        height: 2px;
        background-color: $dark;
        border-radius: 2px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }
      &::before, &::after{
        content: "";
        display: block;
      }
      &::before {
        top: -6px; 
        transition: top 0.075s 0.12s ease,opacity 0.075s ease;
      }
      &::after{
        bottom: -6px;
        transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &{
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &[aria-expanded="true"]{
      #{$this}-inner{
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before{
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease; 
        }
        &::after{
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
// #endregion

// #region Component
  .btn{
    &-primary{
      color: #fff;
      &:hover, &:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, 
      .show > &.dropdown-toggle{
        color: #fff;
      }
    }
    &-outline-primary{
      &:hover, &:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, 
      .show > &.dropdown-toggle{
        background-color: transparent;
        border-color: #34A7FF;
        color: #34A7FF;
      }
    }
  }

  .card{
    box-shadow: 2px 10px 20px -5px rgba(182, 182, 182, 0.29);
  }

  .slick-dots{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li{
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button{
        font-size: 0;
        line-height: 0;
  
        display: block;
  
        width: 10px;
        height: 10px;
        padding: 0;
        border-radius: 50%;
  
        cursor: pointer;
  
        color: transparent;
        border: 0;
        outline: none;
        background: rgba($primary,.3);
      }
      &.slick-active button{
        background: $primary;
      }
    }
  }
// #endregion

// #region Utilities
  .section{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .bg-img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .rtl{direction: rtl;}
  .ltr{direction: ltr;}

  .fz14{
    font-size: rem-calc(14);
    line-height: 1.42857;
  }
  .fz18{font-size: rem-calc(18);}
  .fz24{font-size: rem-calc(24);}
  .fz28{font-size: rem-calc(28);}
  .fz48{font-size: rem-calc(48);}
  .font-weight-medium{font-weight: 500;}
  .font-weight-sbold{font-weight: 600;}

  .z-100{z-index: 100;}
// #endregion

// #region Pages
  body{direction: rtl;}
  .hero{
    height: calc(100vh - 175px);
    max-height: 640px;
    position: relative;
    &::before{
      position: absolute;
      content: '';
      left:0;
      bottom: 0;
      width: 800px;
      height: 554px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='554' viewBox='0 0 800 554'%3E%3Cpath d='M0 0H186.942C240.75 0 292.288 21.6812 329.914 60.1459L796.378 537.007C802.571 543.338 798.085 554 789.229 554H0V0Z' fill='%23233165'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
    }
    &::after{
      position: absolute;
      content: '';
      left: calc(50% + 490px);
      top:220px;
      width: 471px;
      height: 728px;
      //background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='236' height='497' viewBox='0 0 236 497' fill='none'%3E%3Cpath d='M11.3085 149.756C-4.7689 125.025 11.3686 92.0871 40.7641 89.6348L706.884 34.0631C792.337 26.9341 872.747 75.3458 906.431 154.203L1159.17 745.869C1161.83 752.112 1156.89 758.948 1150.13 758.372L383.43 693.148C370.827 692.075 359.424 685.246 352.53 674.642L11.3085 149.756Z' stroke='%23233165' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 471 728' fill='none'%3E%3Cpath d='M8.309 117.756c-16.078-24.73.06-57.669 29.455-60.121l666.12-55.572c85.453-7.129 165.863 41.283 199.547 120.139l252.739 591.667c2.66 6.243-2.28 13.079-9.04 12.503l-762.803-64.893a48.998 48.998 0 01-36.928-22.117L8.309 117.756z' stroke='%23233165' stroke-width='2'/%3E%3C/svg%3E") no-repeat;
    }
    &-slide{
      height: 100%;
      .slick-list, .slick-track, .slide-item{
        z-index: 100;
        height: 100%;
      }
      .container{
        position: relative;
        height: 100%;
      }
    }
    &-indicators{
      list-style: none;
      position: absolute;
      left:50%;
      bottom: -75px;
      transform: translateX(-50%);
      z-index: 100;
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      border-radius: 40px;
      width: 100%;
      max-width: 1080px;
      margin:0 auto;
      padding:0;
      box-shadow: 2px 10px 34px -5px rgba(182, 182, 182, 0.29);
      overflow: hidden;
      li{
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        border-left: 0.5px solid #E9EEF7;
        &:last-child{border-left: 0;}
      }
      button{
        display: block;
        width: 100%;
        border:0;
        padding:rem-calc(35) 0 rem-calc(28);
        background: transparent;
        font-size: rem-calc(18);
        font-weight: 500;
        color: $secondary;
        transition: background-image .15s ease;
        &:hover{
          background-image: linear-gradient(to bottom, rgba(137,204,255,1) 0%,rgba(255,255,255,1) 50%);  
        }
      }
      .active button{
        background-image: linear-gradient(to bottom, rgba(137,204,255,1) 0%,rgba(255,255,255,1) 50%);
      }
      .indicator-icon{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
    &-img{
      position: absolute;
      left: 100px;
      bottom: 0;
      z-index: 10;
      &-blenderinvest{left:50px;}
    }
  }
  .slide-caption{
    position: relative;
    z-index: 100;
    text-align: right;
    padding-top: 70px;
    margin-left: auto;
    .btn{
      box-shadow: 2px 10px 34px -5px rgba(182, 182, 182, 0.29);
    }
  }

  .section-intro{padding-top: 150px;}

  .section-tech{
    position: relative;
    &::before{
      position: absolute;
      content: '';
      right: calc(50% + 520px);
      top:-200px;
      width: 446px;
      height: 566px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 446 566' fill='none'%3E%3Cpath d='M418.726 251.193c35.205 24.945 34.092 77.558-2.138 100.991L138.044 532.346c-60.032 38.829-136.28 42.47-199.74 9.538L-961.152 75.112c-8.125-4.216-5.5-16.458 3.639-16.974L50.609 1.306A41 41 0 0176.62 8.787l342.106 242.406z' stroke='%23232E62' stroke-width='2'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
    }
    &::after{
      position: absolute;
      content: '';
      left: calc(50% + 520px);
      bottom: -60px;
      width: 459px;
      height: 383px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 459 383'%3E%3Cpath d='M38.184 356.927c-37.086-2.546-50.947-49.886-21.092-72.032L346.825 40.296c54.097-40.13 124.76-50.333 188-27.148l336.819 123.485c8.225 3.016 8.872 14.401 1.041 18.328L428.257 377.856a42 42 0 01-21.705 4.358L38.184 356.927z' fill='%23233165'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
    }
    .nav{
      max-width: 1036px;
      margin-left: auto;
      margin-right: auto;
      .nav-link{
        position: relative;
        margin-bottom: 1.5rem;
        border:0;
        background-color: transparent;
        width: 100%;
        color: $primary;
        &::after{
          position: absolute;
          content: '';
          width: 68px;
          height: 4px;
          left:50%;
          margin-left: -34px;
          bottom:-8px;
          background-color: $primary;
          border-radius: 2px;
        }
        &:hover + .tech-desc{opacity: 1;}
        &:focus + .tech-desc{opacity: 1;}
      }
    }
  }
  .tech{
    &-slide{
      max-width: 1036px;
      margin-left: auto;
      margin-right: auto;
      .slide-item{
        background-color: $light;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        &:nth-of-type(2n){
          padding-top: 120px;
        }
        &.hover .rect-shadow{opacity: 1;}
      }
    }
    &-desc{
      color: $primary;
      opacity: 0;
      transition: opacity .15s ease;
      margin-bottom: 0;
    }
    &-img{
      position: relative;
      .rect-white{
        position: relative;
        display: block;
        margin:0 auto;
      }
      .rect-shadow{
        position: absolute;
        left:0;
        bottom:-30px;
        opacity: 0;
        transition: opacity .15s ease;
      }
      .tech-animation{
        mix-blend-mode: multiply;
        display: block;
        position: absolute;
        left:50%;
        top:0;
        transform: translateX(-50%);
      }
    }
  }
  #tech-1{
    width: 144px;
    top:-15px;
  }
  #tech-2{
    width: 160px;
    top:13px;
    margin-left: -3px;
  }
  #tech-3{
    width: 160px;
    top:-10px;
  }
  #tech-4{
    width: 150px;
    top:-35px;
    margin-left: -15px;
  }

  .section-stats{
    background: url(../img/bg-shape.svg) no-repeat -100px 30%;
    padding-top: 180px;
    padding-bottom: 170px;
  }

  .section-license{
    position: relative;
    &::before{
      position: absolute;
      content: '';
      left:calc(50% - 200px);
      bottom:-30px;
      width: 953px;
      height: 245px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='953' height='245' viewBox='0 0 953 245' fill='none'%3E%3Cpath d='M916.638 55.278c52.188 20.104 43.149 96.542-12.288 103.919l-560.086 74.532a199 199 0 01-140.382-34.243L46.497 89.298c-14.63-10.243-8.328-33.181 9.481-34.512l690.65-51.595a129.998 129.998 0 0156.415 8.328l113.595 43.76z' stroke='%23233165' stroke-width='2'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
    }
  }
  .box-white{
    background-color: #fff;
    box-shadow: 2px 10px 34px -5px rgba(182, 182, 182, 0.29);
    border-radius: 40px;
  }
  .license-icon{
    position: relative;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    background: linear-gradient(31.84deg, #69BEFF 6.66%, #34A7FF 108.4%);
    margin:0 auto 15px;
    font-size: rem-calc(26);
    font-weight: 500;
    line-height: 92px;
    color: #fff;
    text-align: center;
    z-index: 10;
    &::before{
      position: absolute;
      content: '';
      top:7px;
      left:7px;
      width: 78px;
      height: 78px;
      border-radius: 50%;
      background: linear-gradient(192.84deg, #95D1FF -11.34%, #34A7FF 90.72%);
      z-index: -1;
    }
  }

  .btn-group > .btn{
    white-space: nowrap;
    border-radius: 40px!important;
    color: $primary;
    &.active{
      background-color: #F5F7FB;
      font-weight: 500;
    }
  }

  .team-img{
    border-radius: 50%;
    box-shadow: 2px 6px 25px -5px rgba(0, 0, 0, 0.225115);
    margin-bottom: 1rem;
  }

  .icon-company-profile{
    max-width: 342px;
    margin-left: auto;
    margin-right: auto;
  }

  .timeline{
    display: flex;
    list-style: none;
    padding:0;
    position: relative;
    margin-top: 250px;
    &::before{
      position: absolute;
      content: '';
      top:26px;
      left:7%;
      right:7%;
      height: 3px;
      background-color: $secondary;
      z-index: -1;
    }
    li{
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
      &:nth-of-type(1), &:nth-of-type(5){
        .timeline-icon{
          margin-bottom: 55px;
        }
      }
      &:nth-of-type(2), &:nth-of-type(6){
        &::before{top:-28px;}
        &::after{top:-41px;}
        .timeline-desc{margin-top: -200px;}
      }
      &:nth-of-type(3){
        &::before{height: 106px;}
        &::after{top:131px;}
        .timeline-icon{margin-bottom: 103px;}
      }
      &:nth-of-type(4){
        &::before{top: -76px;height: 106px;}
        &::after{top:-89px;}
        .timeline-desc{margin-top: -274px;}
      }
      &:nth-of-type(7){
        &::before{height: 142px;}
        &::after{top:167px;}
        .timeline-icon{margin-bottom: 135px;}
      }
      &::before{
        position: absolute;
        content: '';
        left:50%;
        top:25px;
        height: 58px;
        margin-left: -1px;
        width: 2px;
        background-color: $secondary;
        z-index: -1;
      }
      &::after{
        position: absolute;
        content: '';
        width: 13px;
        height: 13px;
        left:50%;
        top:83px;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: $secondary;
      }
    }
    &-icon{
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border:2px solid $secondary;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 10px 20px -5px rgba(182, 182, 182, 0.29);
    }
    &-desc{
      h3{
        font-weight: 400;
        color: $primary;
        margin-bottom: 0;
      }
      p{
        position: relative;
        margin-bottom: 0;
        padding-top: 30px;
        font-weight: 500;
        &::before{
          position: absolute;
          content: '';
          left: 50%;
          margin-left: -18px;
          top:15px;
          width: 36px;
          height: 3px;
          border-radius: 3px;
          background-color: $secondary;
        }
      }
    }
  }

  .nav-tabs{
    position: relative;
    border-bottom:0;
    padding-right: 0;
    &::after{
      position: absolute;
      content: '';
      left:0;
      right:0;
      bottom:0;
      height: 4px;
      border-radius: 2px;
      background-color: #F5F7FB;
    }
    .nav-link{
      font-weight: 500;
      border:0;
      margin-bottom: 0;
      padding:1rem 2rem;
      &.active{
        position: relative;
        &::after{
          position: absolute;
          content: '';
          left:10px;
          right:10px;
          bottom:-1px;
          height: 6px;
          border-radius: 6px;
          background-color: $secondary;
          z-index: 10;
        }
      }
    }
  }

  .list-report{
    padding-left: 10px;
    padding-right: 10px;
    &-item{
      font-size: rem-calc(24);
      padding-top: 24px;
      padding-bottom: 24px;
      &:nth-of-type(2n+1){
        background-color: $light;
      }
      .date{
        text-align: right;
        width: 150px;
      }
      .desc{
        text-align: right;
      }
      .action{
        width: 150px;
        font-weight: 500;
      }
    }
  }

  .card-logo{width: 90px;}
  .tag-col{
    position: relative;
    z-index: 2;
    &::before{
      position: absolute;
      content: '';
      left:15px;
      right: 0;
      height: 2px;
      background-color: $primary;
      border-radius: 2px;
      top:50%;
      margin-top: -1px;
      z-index: -1;
    }
    > div{
      background-color: #fff;
      display: inline-block;
      padding-right: 10px;
    }
  }

  .section-team{
    position: relative;
    z-index: 2;
    &::before{
      position: absolute;
      content: '';
      width: 423px;
      height: 912px;
      right: 0;
      top:-320px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 423 912'%3E%3Cpath d='M50.154 585.134c-34.51-6.181-44.937-50.612-16.779-71.499l342.45-254.032c54.097-40.13 124.76-50.334 188-27.149l339.043 124.301c7.864 2.884 8.911 13.58 1.755 17.933l-448.04 272.549a42.002 42.002 0 01-29.233 5.46L50.154 585.134z' fill='%23232E62'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
      z-index: -1;
    }
  }

  .section-report{
    position: relative;
    z-index: 2;
    &::before{
      position: absolute;
      content: '';
      width: 229px;
      height: 333px;
      left: 0;
      top:-130px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 229 333' fill='none'%3E%3Cpath d='M208.112 189.428C234.804 207.112 224.486 248.521 192.617 251.613L26.5122 267.729C-8.09338 271.086 -42.9747 265.319 -74.6576 251L-404.901 101.756C-413.205 98.003 -411.314 85.7029 -402.266 84.6182L-43.4554 41.6021C-27.9066 39.738 -12.2017 43.4634 0.853206 52.1126L208.112 189.428Z' stroke='%23232E62' stroke-width='2'/%3E%3C/svg%3E") no-repeat;
      background-size: contain;
      z-index: -1;
    }
  }

  .footer{
    background: url("data:image/svg+xml,%3Csvg width='1920' height='452' viewBox='0 0 1920 452' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M-270.268 263.991c-32.102-21.459-18.267-71.373 20.281-73.17l569.038-26.514c67.222-3.132 131.57 27.725 171.245 82.116l288.1 394.963c5.359 7.347-1.063 17.437-9.987 15.69L139.496 533.985a42.124 42.124 0 01-15.31-6.316l-394.454-263.678z' fill='%23F5F7FB'/%3E%3Cpath d='M-103.922 403.615c-32.102-21.459-18.267-71.373 20.28-73.17l630.57-29.38a100.222 100.222 0 0177.773 31.579L1037.68 773.22c6.53 6.96.55 18.216-8.87 16.697L306.614 673.448a42.101 42.101 0 01-16.692-6.563l-393.844-263.27z' fill='%23232E62'/%3E%3Cpath d='M1378.7 172.159c-3.87-29.23 24.96-52.056 52.58-41.636l626.69 236.398c80.16 30.237 131.8 108.32 128.21 193.835l-26.96 640.974c-.28 6.77-7.7 10.82-13.56 7.4l-665.19-388.237a40.837 40.837 0 01-19.91-29.911l-81.86-618.823z' stroke='%233B3C3D' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' transform='translate(-371.273 .218)' d='M0 0h2801.93v1218.17H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") no-repeat center bottom;
    text-align: center;
    padding-top: 200px;
    padding-bottom: 42px;
    font-size: rem-calc(14);
  }
// #endregion

@media (min-width: 1468px) {
  .section-stats{
    background-position: center 30%;
  }
}
@media (min-width: 1280px) {
  .hamburger{display: none;}
}

@media (max-width: 1279px) {
  #navigation{
    position: absolute;
    left: 0;
    right: 0;
    top:98px;
    z-index: 900;
    background-color: #fff;
    padding-bottom: 20px;
    border-radius: 0 0 20px 20px;
    box-shadow: 2px 10px 34px -5px rgba(0,0,0,0.1);
    .navbar-nav, .mr-auto{width: 100%;}
    .navbar-nav{order: 2;}
    .mr-auto{
      order: 1;
      .list-unstyled{
        flex-direction: column;
        li{
          border-top:1px solid $light;
        }
        .btn{
          display: block;
          text-align: left;
          max-width: 940px;
          margin-left: auto;
          margin-right: auto;
          padding:rem-calc(14) 0;
          border:0;
          background-color: transparent;
          color: $primary;
          box-shadow: none!important;
          border-radius: 0;
          font-size: 1rem;
        }
      }
    }
  }
  .navbar{
    &-collapse-inner{
      flex-wrap: wrap;
      .dropdown-menu{
        left:0;
        transform: none;
        background-color: #fff;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none;
      }
      .dropdown-item{
        text-align: left;
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;
        padding:rem-calc(14) 1rem;
        font-size: 1rem;
      }
    }
    &-nav{
      .nav-item{
        border-top:1px solid $light;
      }
      .nav-link{
        position: relative;
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;
        padding:rem-calc(14) 0;
        font-size: 1rem;
      }
      .dropdown-toggle::after{
        position: absolute;
        margin-left: 0;
        right: 0;
        top:22px;
        border:0;
        width: 14px;
        height: 8px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8' fill='none' %3E%3Cpath d='M1 1l6 6 6-6' stroke='%233B3C3D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat;
        background-size: contain;
        transition: transform .15s ease;
      }
      .dropdown-toggle[aria-expanded="true"]::after{
        transform: rotateX(180deg);
      }
    }
  }

  .hero{
    &::before{left:-160px;}
    &::after{left:calc(50% + 270px);}
    &-img{left:0;}
    &-img-blenderpay{left:-130px;}
    &-img-blenderloans{
      left:-40px;
      + .slide-caption{max-width: 510px;}
    }
    &-img-blenderinvest{left:-150px;}
    &-img-blenderbank{left:-140px;}
    &-indicators{max-width: 940px;}
  }

  .section-tech .nav .nav-link:focus-visible + .tech-desc{
    opacity: 1;
  }
  .tech-slide .slick-list{overflow: visible;}
}
@media (max-width: 1199px) {
  .section-tech{
    &::before{
      right:calc(50% + 380px);
      top:-270px;
    }
    &::after{
      left:calc(50% + 330px);
    }
  }
  .timeline-desc{
    margin-left: -22px;
    margin-right: -22px;
  }
  .footer{
    background-size: 1400px;
    padding-top: 115px;
  }
}
@media (max-width: 991px) {
  h3, .h3{font-size: rem-calc(24);}

  #navigation .mr-auto .list-unstyled .btn, .navbar-nav .nav-link, .navbar-collapse-inner .dropdown-item{max-width: 700px;}

  .hero{
    &::before{left:-260px;}
    &::after{top:310px;left:calc(50% + 150px);}
    &-img{max-width: 500px;}
    &-img-blenderpay{left:-110px;}
    &-img-blenderloans + .slide-caption{max-width: 460px;}
    &-img-blenderinvest, &-img-blenderbank{left:-120px;}
    &-indicators{
      max-width: 700px;
      button{font-size: 1rem;}
    }
  }
  .slide-caption{max-width: 460px;}

  .section-tech .nav .nav-link{font-size: rem-calc(18);}
  .tech-slide .slide-item:nth-of-type(2n){padding-top: 140px;}
  .section-tech{
    &::before{
      right:calc(50% + 280px);
      top:-290px;
    }
    &::after{
      left:calc(50% + 230px);
    }
  }

  .section-stats{background-position: -200px 30%;}
  .btn-group > .btn{
    padding-left: 10px;
    padding-left: 10px;
    font-size: rem-calc(14);
  }

  .section-team::before{
    width: 300px;
    height: 650px;
    top:-200px;
  }
  .section-report::before{
    width: 150px;
    height: 220px;
    top:-50px;
  }
  .list-awards{
    justify-content: center;
    margin-left: -1rem;
    margin-right: -1rem;
    .nav-item{
      flex: initial;
    }
  }
}
@media (max-width: 767px) {
  h1, .h1{font-size: 3rem;}
  h2, .h2{font-size: 2rem;}
  .fz18{font-size: 1rem;}
  .fz24{font-size: rem-calc(20);}
  .fz28{font-size: rem-calc(22);}
  .fz48{font-size: rem-calc(38);}

  .section{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #navigation .mr-auto .list-unstyled .btn, .navbar-nav .nav-link, .navbar-collapse-inner .dropdown-item{max-width: 520px;}
  .hero{
    height: auto;
    max-height: none;
    padding-bottom: 20px;
    &::before{
      width: 50vw;
      height: 90vw;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 466'%3E%3Cpath d='M0 0h45.096a20 20 0 0118.499 12.398L250 466H0V0z' fill='%23233165'/%3E%3C/svg%3E");
      background-size: contain;
    }
    &::after{
      top:auto;
      bottom: -480px;
    }
    &-slide{
      height: auto;
      .slick-slide img{display: none;}
    }
    &-indicators{
      position: relative;
      bottom: auto;
      left: auto;
      transform: none;
      max-width: 520px;
      flex-direction: column;
      background-color: transparent;
      overflow: visible;
      box-shadow: none;
      li{
        margin-bottom: 1rem;
        border-left: 0;
      }
      button{
        position: relative;
        background-color: #fff;
        display: flex;
        direction: ltr;
        align-items: center;
        border-radius: 20px;
        padding:14px 20px;
        font-size: rem-calc(20);
        box-shadow: 2px 10px 34px -5px rgba(0, 0, 0, 0.1);
        &::after{
          position: absolute;
          content: '';
          right:20px;
          top:calc(50% - 18px);
          width: 36px;
          height: 36px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' fill='none'%3E%3Ccircle cx='18' cy='18' r='18' fill='%2369BEFF'/%3E%3Cpath d='M15 24l6-6-6-6' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat;
          background-size: contain;
        }
      }
      .indicator-icon{
        margin-bottom: 0;
        margin-right: 15px;
      }
    }
  }
  .slide-caption{
    padding-top: 20px;
    text-align: center;
    max-width: none!important;
    .btn{display: none;}
  }
  .section-intro{padding-top: 50px;}
  .tech-slide .slide-item:nth-of-type(2n){padding-top: 40px;}
  .section-tech{
    &::before{
      right:auto;
      left: 0;
      top:-90px;
      width: 80px;
      height: 168px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 168' fill='none'%3E%3Cpath d='M70.896 76.253c11.954 9.138 9.917 27.707-3.735 34.035L-53.09 166.031-328.508 23.894c-8.294-4.28-5.392-16.801 3.94-16.996l282.29-5.887A41 41 0 01-16.522 9.43l87.42 66.824z' stroke='%23232E62' stroke-width='2'/%3E%3C/svg%3E");
    }
    &::after{
      left:auto;
      right:0;
      bottom:-40px;
      width: 122px;
      height: 127px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 122 127'%3E%3Cpath d='M18.308 108.986c-20.094-3.442-25.104-30.001-7.644-40.526L123 .744 123.5 45v82L18.308 108.986z' fill='%23233165'/%3E%3C/svg%3E");
    }
    .nav{
      max-width: 450px;
      .tech-desc{display: none;}
    }
  }
  .tech-img .rect-shadow{opacity: 1;}

  .section-stats{
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: -310px 30%;
  }
  .section-license::before{
    bottom: 0;
  }
  .section-contact .btn-group{
    display: inline-block;
    .btn{margin-bottom: 10px;}
  }

  .section-team::before{
    width: 172px;
    height: 580px;
    top:60%;
    margin-top: -290px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 172 580'%3E%3Cpath d='M48.796 385.041C14.8973 378.118 5.18096 334.337 32.9686 313.724L203.575 187.167C257.672 147.037 328.335 136.833 391.575 160.018L570.144 225.486C578.231 228.451 579.036 239.57 571.461 243.67L252.021 416.533C243.345 421.228 233.293 422.72 223.628 420.746L48.796 385.041Z' fill='%23232E62'/%3E%3C/svg%3E%0A");
  }

  .timeline{
    margin: 0 auto;
    display: block;
    max-width: 300px;
    &::before{
      display: none;
    }
    li{
      position: relative;
      display: flex;
      text-align: right;
      margin-bottom: 1.5rem;
      &::before{
        top:50px!important;
        bottom:-1.5rem;
        right: 97px;
        left: auto;
        height: auto;
        margin-left: 0;
      }
      &:last-child::before, &::after{display: none;}
      &:nth-of-type(1) .timeline-desc p:nth-of-type(1),
      &:nth-of-type(3) .timeline-desc p:nth-of-type(1),
      &:nth-of-type(4) .timeline-desc p:nth-of-type(1),
      &:nth-of-type(5) .timeline-desc p:nth-of-type(1){padding-top: 4px;}
    }
    &-icon{
      margin:0 70px 0 0!important;
    }
    &-desc{
      margin:0!important;
      flex: auto;
      padding-right: 1rem;
      h3{
        position: absolute;
        right:0;
        top:13px;
      }
      p{
        padding-top: 15px;
        &::before{
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-left: 36px;
          margin-top: 2px;
          top:50%;
          left: 100%;
          z-index: -1;
        }
      }
    }
  }

  .list-awards .nav-item img{max-height: 60px;}

  .footer{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 375 238' fill='none'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M-89.793 149.79c-27.86-24.18-11-69.906 25.87-70.158l85.3-.584a68.16 68.16 0 0158.907 33.101l80.406 134.074c4.691 7.821-2.669 17.307-11.412 14.711l-160.003-47.505a42.11 42.11 0 01-15.615-8.566l-63.453-55.073z' fill='%23F5F7FB'/%3E%3Cpath d='M-68.994 221.573c-32.102-21.459-18.267-71.373 20.28-73.17l126.85-5.91a100.225 100.225 0 0177.774 31.579l119.707 127.705c6.524 6.961.543 18.216-8.873 16.697L24.366 279.386a42.095 42.095 0 01-16.691-6.563l-76.67-51.25z' fill='%23232E62'/%3E%3Cpath d='M297.776 132.716c-.891-13.783 12.804-23.903 25.742-19.022l361.84 136.492c84.709 31.954 136.992 116.99 127.225 206.926l-35.931 330.846c-.705 6.489-7.882 10.107-13.52 6.816L342.771 549.429a40.828 40.828 0 01-20.175-32.634l-24.82-384.079z' stroke='%23232E62' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' transform='translate(-169.793 .782)' d='M0 0h1118.97v802.648H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-size: cover;
    padding-bottom: 30vw;
    padding-top: 50px;
  }
}
@media (max-width: 575px) {
  h1, .h1{font-size: 2rem;}
  h2, .h2{font-size: 1.5rem;}
  .fz28{font-size: rem-calc(18);}
  .fz48{font-size: rem-calc(32);}

  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
  #navigation .mr-auto .list-unstyled .btn, .navbar-nav .nav-link, .navbar-collapse-inner .dropdown-item{max-width: calc(100vw - 40px);}

  .hero{
    &::before{
      width: 66.66667vw;
      height: 124.26667vw;
    }
    &::after{
      right:0;
      bottom:-80px;
      width: 114px;
      height: 276px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 114 276' fill='none'%3E%3Cpath d='M10.076 80.716C1.108 65.209 8.204 45.36 24.97 39.054l91.842-34.547 44.587 121.271a40.998 40.998 0 01-.462 29.495L116.65 264.991 10.076 80.716z' stroke='%23233165' stroke-width='2'/%3E%3C/svg%3E");
      background-size: contain;
    }
    &-indicators{
      max-width: calc(100vw - 40px);
    }
  }
  .section-tech .nav{
    max-width: 335px;
    .nav-link{
      padding-left: 5px;
      padding-right: 5px;
      &::after{
        opacity: 0;
        transition: opacity .15s ease;
      }
    }
    .active .nav-link::after{opacity: 1;}
  }
  .tech-slide .slide-item{
    flex-wrap: wrap;
    padding-bottom: 0;
    text-align: center;
  }
  .tech-img{margin-bottom: 50px;}
  .section-stats{
    padding-top: 170px;
    padding-bottom: 130px;
    min-height: 725px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 375 725'%3E%3Cpath d='M-5.182 134.16L-1.5 632.5l271.868 82.723c18.351 5.584 37.986-3.385 45.782-20.911L375 562V88.5L77.17 7.95c-14.407-3.896-29.488 3.39-35.388 17.1L-5.182 134.16z' fill='%23F5F7FB'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
  }
  .section-contact .btn-group{display: inline-flex;}

  .section-team::before{top:42%}
  .list-awards{
    margin-left: -.5rem;
    margin-right: -.5rem;
    .nav-item img{max-height: 40px;}
  }
  .section-stock hr{max-width: 218px;}
  .section-report::before{top:-120px;left:-60px;}
  .nav-tabs .nav-link{
    padding-left: 12px;
    padding-right: 12px;
  }
  .nav-tabs .nav-link.active::after{left:0;right:0;}

  .list-report-item{
    border-radius: 10px;
    font-size: rem-calc(20);
    background-color: $light;
    margin-bottom: 1rem;
    .date, .desc, .action{
      width: auto;
      text-align: center;
    }
    .date{font-size: 1rem;}
  }

  .footer{
    background-size: contain;
    padding-bottom: 42vw;
  }
}
